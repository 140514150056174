import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { EnrichedService, Resources, ViewMode } from '../../types/types';
import { WidgetData } from '../../../legacy/types';
import { createWidgetViewModel as createWidgetViewModelDeprecated } from '../../viewModelDeprecated/viewModel';
import { createWidgetViewModel } from '../../viewModel/viewModel';
import { getCourseAvailability as getCourseAvailabilityDeprecated } from '../../actionsDeprecated/courseAvailability/courseAvailability';
import { filterWidgetData } from '../../utils/filters/filter-widget-data';
import { ActiveFeatures } from '@wix/bookings-uou-types';
import {
  Category,
  Location,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { ServiceListContext } from '../../context/createServiceListContext';
import { BookingsAPI } from '../../api/BookingsApi';
import { getAdditionalServicesData } from '../../actions/getAdditionalServicesData/getAdditionalServicesData';

export const updateSettings = async ({
  businessLocations,
  categories,
  serviceListContext,
  services,
  dangerousPublicDataOverride,
  dangerousStylesOverride,
  presetId,
  scale,
  viewMode,
  flowAPI,
  newData,
  shouldWorkWithAppSettings,
  bookingsApi,
}: {
  presetId: string;
  flowAPI: ControllerFlowAPI;
  dangerousStylesOverride: Function;
  dangerousPublicDataOverride: Function;
  scale: number;
  viewMode: ViewMode;
  newData: any;
  shouldWorkWithAppSettings: boolean;
  businessLocations: Location[];
  categories: Category[];
  services: EnrichedService[];
  serviceListContext: ServiceListContext;
  bookingsApi: BookingsAPI;
}) => {
  const { controllerConfig } = flowAPI;
  const { setProps } = controllerConfig;

  const publicData = dangerousPublicDataOverride(
    mergePresetIdToPublicData(newData.publicData, presetId),
  );

  const styleParams = dangerousStylesOverride(
    mergePresetIdToStyleParams(newData.style.styleParams, presetId),
  );

  const widgetViewModel = await createWidgetViewModel({
    scale,
    flowAPI,
    viewMode,
    shouldWorkWithAppSettings,
    businessLocations,
    categories,
    serviceListContext,
    services,
  });

  const { coursesAvailability, services: populatedServices } =
    await getAdditionalServicesData({ bookingsApi, services });

  widgetViewModel.coursesAvailability = coursesAvailability;
  widgetViewModel.services = populatedServices;

  setProps({
    widgetViewModel,
    ...styleParams,
    ...publicData,
  });
};

export const updateSettingsDeprecated = async ({
  filteredResources,
  userData,
  dangerousPublicDataOverride,
  dangerousStylesOverride,
  presetId,
  scale,
  viewMode,
  flowAPI,
  newData,
  shouldWorkWithAppSettings,
}: {
  filteredResources: Resources;
  userData: WidgetData;
  presetId: string;
  flowAPI: ControllerFlowAPI;
  dangerousStylesOverride: Function;
  dangerousPublicDataOverride: Function;
  scale: number;
  viewMode: ViewMode;
  newData: any;
  shouldWorkWithAppSettings: boolean;
}) => {
  const { controllerConfig } = flowAPI;
  const { setProps } = controllerConfig;
  const isEditor = true;

  const publicData = dangerousPublicDataOverride(
    mergePresetIdToPublicData(newData.publicData, presetId),
  );

  const styleParams = dangerousStylesOverride(
    mergePresetIdToStyleParams(newData.style.styleParams, presetId),
  );

  if (userData.offerings && userData.offerings.length) {
    filteredResources = filterWidgetData(userData, flowAPI, presetId, isEditor);
  }
  const widgetViewModel = await createWidgetViewModelDeprecated({
    scale,
    businessInfo: userData.config.businessInfo,
    filteredResources,
    flowAPI,
    viewMode,
    shouldWorkWithAppSettings,
    activeFeatures: {} as ActiveFeatures,
  });

  widgetViewModel.coursesAvailability = await getCourseAvailabilityDeprecated(
    flowAPI,
    filteredResources,
  );

  setProps({
    widgetViewModel,
    ...styleParams,
    ...publicData,
  });
};

export const mergePresetIdToStyleParams = (
  stylesParams: any,
  presetId: string,
) => {
  return {
    ...stylesParams,
    numbers: { ...stylesParams.numbers, presetId },
  };
};

export const mergePresetIdToPublicData = (
  publicData: { APP: { [p: string]: any }; COMPONENT: { [p: string]: any } },
  presetId: string,
) => {
  const COMPONENT = {
    ...publicData.COMPONENT,
    presetId,
  };
  return { APP: publicData.APP, COMPONENT };
};
